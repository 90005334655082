var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{attrs:{"padless":true}},[_c('v-card',{staticClass:"text-start pt-10",attrs:{"flat":"","tile":"","width":"100%","color":"#EBF1F7"}},[_c('v-container',[_c('v-row',{staticClass:"mx-0 my-0"},[_c('v-col',{attrs:{"cols":"4","md":"3"}},[_c('v-img',{attrs:{"width":"150","contain":"","src":require("@/assets/img/edifier-logo-gris.svg")}}),_c('div',{staticClass:"mt-10"},[_c('div',{staticStyle:{"color":"#67696b"}},[_vm._v("Seguinos")]),_c('div',{staticClass:"d-flex justify-start justify-md-start mt-1"},[_c('v-icon',{staticClass:"mx-0 px-0 cursor-pointer",attrs:{"color":"#67696B"},on:{"click":function($event){return _vm.HandlerSocialLink('https://es-la.facebook.com/edifier.cl/')}}},[_vm._v(" mdi-facebook ")]),_c('v-icon',{staticClass:"mx-2 cursor-pointer",attrs:{"color":"#67696B"},on:{"click":function($event){return _vm.HandlerSocialLink(
                    'https://www.instagram.com/edifier.chile/'
                  )}}},[_vm._v(" mdi-instagram ")]),_c('v-icon',{staticClass:"mx-0 cursor-pointer",attrs:{"color":"#67696B"},on:{"click":function($event){return _vm.HandlerSocialLink(
                    'https://www.youtube.com/channel/UCL3TSzB0rmeBxL0PMkPA18w'
                  )}}},[_vm._v(" mdi-youtube ")])],1)])],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"4","md":"2"}},[_c('div',{staticClass:"models-footer"},[_c('div',{staticClass:"\n                d-flex\n                justify-start\n                algin-start\n                font-weight-bold\n                ml-2\n                models-footer-text\n              "},[_vm._v(" Modelos ")]),_vm._l((_vm.categories),function(item,index){return _c('div',{key:index,staticClass:"ml-2 mt-1",staticStyle:{"color":"#67696b"},on:{"click":function($event){return _vm.productsCategores(item)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [_c('span',{style:(hover
                      ? 'color: #00A0E9; cursor: pointer'
                      : '#67696b; cursor: pointer')},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)})],1)})],2)]),_c('v-col',{staticClass:"d-flex justify-md-center",attrs:{"cols":"4","md":"2"}},[_c('div',{staticClass:"models-footer"},[_c('div',{staticClass:"\n                d-flex\n                justify-start\n                algin-start\n                font-weight-bold\n                ml-2\n                models-footer-text\n              "},[_vm._v(" Showroom ")]),_vm._l((_vm.showroom),function(item,index){return _c('div',{key:index,staticClass:"ml-2 mt-1",style:(item.name == '.' ? 'color: #EBF1F7' : 'color: #67696b'),on:{"click":function($event){return _vm.goToContact(item.name)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var hover = ref.hover;
return [_c('span',{style:(hover
                      ? 'color: #00A0E9; cursor: pointer'
                      : '#67696b; cursor: pointer')},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)})],1)})],2)]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"4","md":"2"}},[_c('div',{staticClass:"models-footer"},[_c('div',{staticClass:"\n                d-flex\n                justify-start\n                algin-start\n                font-weight-bold\n                ml-2\n                models-footer-text\n              "},[_vm._v(" Soporte ")]),_vm._l((_vm.support),function(item,index){return _c('div',{key:index,staticClass:"ml-2 mt-1",style:(item.name == '.' ? 'color: #EBF1F7' : 'color: #67696b'),on:{"click":function($event){return _vm.goSection(item)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var hover = ref.hover;
return [_c('span',{style:(hover
                      ? 'color: #00A0E9; cursor: pointer'
                      : '#67696b; cursor: pointer')},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)})],1)})],2)]),_c('v-col',{staticClass:"d-flex justify-md-center",attrs:{"cols":"4","md":"2"}},[_c('div',{staticClass:"models-footer"},[_c('div',{staticClass:"\n                d-flex\n                justify-start\n                algin-start\n                font-weight-bold\n                ml-2\n                models-footer-text\n              "},[_vm._v(" Empresa ")]),_vm._l((_vm.company),function(item,index){return _c('div',{key:index,staticClass:"ml-2 mt-1",style:(item.name == '.' ? 'color: #EBF1F7' : 'color: #67696b'),on:{"click":function($event){return _vm.goToContact(item.name)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var hover = ref.hover;
return [_c('span',{style:(hover
                      ? 'color: #00A0E9; cursor: pointer'
                      : '#67696b; cursor: pointer')},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)})],1)})],2)])],1)],1),_c('div',{staticClass:"mt-10 background-footer"},[_c('v-container',[_c('span',{staticStyle:{"color":"#67696b"}},[_vm._v(" © EDIFIER "+_vm._s(_vm.getYear)+" Todos los derechos reservados "),_c('span',{staticClass:"ml-md-15"},[_vm._v("Política de privacidad")]),_c('span',{staticClass:"ml-md-15",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.preventDefault();_vm.showTerms = true}}},[_vm._v(" Términos de uso ")])])])],1)],1),(_vm.showTerms)?_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.showTerms),callback:function ($$v) {_vm.showTerms=$$v},expression:"showTerms"}},[_c('v-card',{staticStyle:{"padding":"0 17px 17px"}},[_c('v-card-text',[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"pt-0 mt-2",attrs:{"icon":""},on:{"click":function($event){_vm.showTerms = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('p',{staticStyle:{"font-weight":"500"}},[_vm._v(" La compra está sujeta a disponibilidad de stock, así como también a condiciones de logística y distribución. En caso de no poder hacerse la entrega del o de los productos solicitados debido a la falta de disponibilidad se dará por anulada la compra. Si el cliente desea adquirir un nuevo producto, la compra estará regida por las condiciones comerciales correspondientes al momento. ")]),_c('p',{staticStyle:{"font-weight":"500"}},[_vm._v(" La empresa se reserva el derecho de cancelación dentro las 72hs con la devolución del pago por el mismo medio en que se efectuó la compra, sin perjucio o daño. ")])])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }