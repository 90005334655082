<template>
  <div>
    <v-sheet>
      <v-img
        src="../../assets/img/sections/showRoom.jpg"
        lazy-src="../../assets/img/sections/showRoom.jpg"
      >
      </v-img>
    </v-sheet>
    <v-container>
      <v-row justify="center" class="mt-7">
        <v-col cols="12" sm="12" md="10">
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <div>
                Nuestro Showroom se encuentra en nuestras bodegas central,
                frente al Estadio Nacional, con facil acceso y cómodo
                estacionamiento.
              </div>
              <div class="chip my-5">
                <span class="pl-2"
                  >Av. Marathon 1315, Ñuñoa, Región Metropolitana, Chile</span
                >
              </div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3328.412696333112!2d-70.61970868480036!3d-33.46460458076984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cff4f435a467%3A0x5681715bdf17f635!2sAv.%20Marathon%201315%2C%20%C3%91u%C3%B1oa%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1ses!2sve!4v1643923022758!5m2!1ses!2sve"
                width="100%"
                height="600"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <div class="color-text">
                Reserva una visita a nuestro Showroom
              </div>
              <div class="mt-3">
                <label for="Nombre">Nombre</label>
                <ValidationProvider
                  name="nombre"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="mt-2 elevation-0"
                    color="#00A0E9"
                    dense
                    filled
                    flat
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
                <label for="Nombre">Apellido</label>
                <ValidationProvider
                  name="Apellido"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="mt-2 elevation-0"
                    color="#00A0E9"
                    dense
                    filled
                    flat
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
                <label for="Nombre">Email</label>
                <ValidationProvider
                  name="Emil"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="mt-2 elevation-0"
                    color="#00A0E9"
                    dense
                    filled
                    flat
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
                <label for="Nombre">Teléfono</label>
                <ValidationProvider
                  name="Emil"
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="mt-2 elevation-0"
                    color="#00A0E9"
                    dense
                    filled
                    flat
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </div>
              <div class="simple-example">
                <vue-meeting-selector
                  v-model="meeting"
                  :date="date"
                  :loading="loading"
                  :meetings-days="meetingsDays"
                  @next-date="nextDate"
                  @previous-date="previousDate"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import moment from "moment";
import moment from 'moment-timezone';
import VueMeetingSelector from "vue-meeting-selector";

export default {
  name: "SimpleExample",
  components: {
    "vue-meeting-selector": VueMeetingSelector,
  },
  data() {
    return {
      currentDate: moment().subtract(1, "days").tz("Chile/Continental"),
      date: new Date(),
      meetingsDays: [],
      meeting: [],
      loading: true,
      nbDaysToDisplay: 5,

      count: 0,
    };
  },
  async created() {
    this.loading = true;
    this.meetingsDays = await this.getMeetings(1);
    this.loading = false;
  },
  computed: {
    // because of line-height, font-type you might need to change top value
    classNames() {
      return {
        tabLoading: "loading-div",
      };
    },
  },
  methods: {
    getMeetings(action) {
      const now = this.currentDate;

      let array_fecha = [];

      let valor = 6;
      Array.from({ length: 5 }, (_, index) => {
        let day;
        if (action === 1) {
          day = now.add(1, "days").format("YYYY-MM-DD 09:00:00");
        } else {
          day = now.subtract(1, "days").format("YYYY-MM-DD 09:00:00");
          console.log("day", day);
        }
        let value = 0;
        let slot = [];
        Array.from({ length: 15 }, (_, index) => {
          value = value + 30;
          slot.push({ date: moment(day).add(value, "minutes").toISOString() });
        });

        array_fecha.push({
          date: moment(day).toISOString(),
          slots: slot,
        });
      });

      array_fecha.sort(function (a, b) {
        if (a.date > b.date) {
          return 1;
        }
        if (a.date < b.date) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });

      return array_fecha;
    },

    nextDate() {
      this.loading = true;
      this.meetingsDays = this.getMeetings(1);
      this.date = new Date(
        moment(this.currentDate).format("YYYY-MM-DD 09:00:00")
      );
      this.loading = false;
    },

    previousDate() {
      this.loading = true;
      this.count++;

      if (this.count === 1) {
        this.currentDate = this.currentDate.subtract(4, "days");
      }

      this.meetingsDays = this.getMeetings(0);
      // this.date = new Date(moment(this.currentDate).format("YYYY-MM-DD 09:00:00"));
      this.loading = false;
    },
  },
};
</script>


  <style scoped lang="scss">
.simple-example {
  margin-top: 3em;
  &__meeting-selector {
    max-width: 542px;
  }
}
// since our scss is scoped we need to use ::v-deep
::v-deep .loading-div {
  top: 58px !important;
}

.color-text {
  color: #00a0e9;
  font-size: 1.2em;
}
.chip {
  background-color: #00a0e9;
  color: white;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 12px;
}
</style>
