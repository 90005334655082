<template>
  <div>
    <div v-if="isAuth && !dataProduct.out_stock" class="mb-0">
      <span v-if="$route.name != 'product_details' && validateUmbral() == true">
        <span :class="centerElement()">
          <v-icon color="black" class="mr-1" size="20">
            mdi-truck-outline
          </v-icon>
          Disponible
        </span>
        <v-btn
          class="mt-5"
          @click="HandlerShowProduct(dataProduct)"
          outlined
          rounded
          color="#15A7EB"
        >
          Comprar
        </v-btn>
      </span>
      <span v-if="!validateUmbral()">
        <span :class="centerElement('mb-3')">
          <span class="text-uppercase">Ingresa en {{ getMonth() }}</span>
        </span>
        <v-btn
          v-if="dataProduct.user_product_notification === null"
          outlined
          rounded
          color="#15A7EB"
          @click="HandlerModalAvisame()"
        >
          AVISAME
        </v-btn>
        <br v-if="dataProduct.user_product_notification != null" />
        <span
          v-if="dataProduct.user_product_notification != null"
          style="color: #00a0e9"
        >
          TE AVISAMOS CUANDO ESTÉ
        </span>
      </span>
    </div>

    <div v-else-if="isAuth && dataProduct.out_stock">
      <p style="font-size: 1.2em" class="mb-0 pt-1">
        <span
          v-if="
            dataProduct.user_product_notification == null &&
            $route.name == 'products'
          "
          class="black--text d-flex justify-center mt-n1 mb-4 text-uppercase"
          style="cursor: default"
        >
          Ingresa en {{ getMonth() }}
        </span>
      </p>

      <v-btn
        v-if="dataProduct.user_product_notification == null"
        @click="HandlerModalAvisame()"
        class="mt-0"
        rounded
        outlined
        color="#3FB7EE"
      >
        AVISAME
      </v-btn>
      <p
        class="mb-0 text-uppercase"
        style="font-size: 1.2em"
        v-else-if="dataProduct.user_product_notification != null"
      >
        <span
          class="black--text d-flex justify-center mt-n1 mb-6"
          style="cursor: default"
          v-if="$route.name == 'products'"
        >
          LO ELEGISTE
        </span>
        <span style="color: #00a0e9"> TE AVISAMOS CUANDO ESTÉ </span>
      </p>
    </div>

    <div v-else-if="!isAuth" class="mb-5">
      <p
        class="mb-0 text-uppercase"
        style="cursor: pointer; color: #3f3c35"
        @click="$router.push({ name: 'login' })"
      >
        <v-icon color="#3F3C35" class="mr-1">mdi-truck-outline</v-icon>
        <span v-if="dataProduct.out_stock == false"> Disponible </span>
        <span v-else class="text-uppercase"> Ingresa en {{ getMonth() }} </span>
      </p>
    </div>

    <ValidationObserver ref="obs" v-slot="{ passes }">
      <v-dialog
        v-if="showModalReserve"
        v-model="showModalReserve"
        max-width="600"
      >
        <v-card>
          <v-card-title>
            Completá con tus datos y nos comunicaremos
          </v-card-title>
          <v-card-text>
            <ValidationProvider
              name="nombre"
              rules="required"
              v-slot="{ errors }"
            >
              <v-text-field
                @keyup="passes(HandlerNotification)"
                filled
                rounded
                v-model="authUserData.buyer.first_name"
                label="Nombre"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider
              name="email"
              rules="email|required"
              v-slot="{ errors }"
            >
              <v-text-field
                @keyup="passes(HandlerNotification)"
                filled
                rounded
                label="Email"
                v-model="authUserData.buyer.email"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider
              name="teléfono"
              rules="numeric|min:8|required"
              v-slot="{ errors }"
            >
              <v-text-field
                @keyup="passes(HandlerNotification)"
                filled
                rounded
                label="Teléfono"
                v-model="authUserData.buyer.phone"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="showModalReserve = false">Cancelar</v-btn>
            <v-btn
              :loading="loading"
              dark
              color="#00A0E9"
              @click="passes(HandlerNotification)"
            >
              Continuar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </ValidationObserver>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    dataProduct: {
      type: Object,
      required: false,
      default: () => {},
    },
    authUser: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      loading: false,
      showModalReserve: false,

      //Data
      email: "",
      name: "",
      phone: "",
      responseChazki: null,

      //Notification
      activeNotificacion: false,
      textNotification: "",
      colorNotification: "black",
    };
  },

  async created() {
    if (this.isAuth) {
      const responseChazki = await this.$store.dispatch(
        "products/CHAZKI_VALIDATE"
      );
      this.responseChazki = responseChazki.data.data;
    }
  },

  computed: {
    isAuth() {
      return this.$store.getters["auth/AUTHENTICATED"];
    },

    authUserData() {
      return this.$store.getters["auth/GET_PROFILE"];
    },
  },

  methods: {
    HandlerReturnWarehouse(cp, warehouse) {
      switch (parseInt(cp)) {
        default:
          return this.getDepostCentral(warehouse, cp, 13);
      }
    },

    getDepostCentral(warehouse, cp, cenId) {
      const getWarehouseFwl01 = warehouse.find(
        (value) => value.warehouse_id == cenId
      );

      if (getWarehouseFwl01 != undefined) {
        if (getWarehouseFwl01.current_stock > 0) {
          if (cp >= 1000 && cp < 1441) {
            let text = this.$route.name == "cart" ? "Llega" : "RECIBILO";
            return `${text} dentro de las 24Hs HÁBILES`;
          } else if (this.responseChazki == true) {
            let text = this.$route.name == "cart" ? "Llega" : "RECIBILO";
            return `${text} dentro de las 72Hs HÁBILES`;
          } else {
            let text = this.$route.name == "cart" ? "Llega" : "RECIBILO";
            return `${text} en 4-6 días hábiles`;
          }
        } else {
          return "AVISAME";
        }
      }
    },

    getDeposit(warehouse, regId, cenId) {
      const getWarehouseReg = warehouse.find(
        (value) => value.warehouse_id == regId
      );

      const getWarehouseFwl01 = warehouse.find(
        (value) => value.warehouse_id == cenId
      );

      if (getWarehouseReg != undefined && getWarehouseFwl01 != undefined) {
        if (
          getWarehouseReg.current_stock > 0 &&
          getWarehouseFwl01.current_stock > 0
        ) {
          // if (this.warehouseValue === 5) {
          let text = this.$route.name == "cart" ? "Llega" : "RECIBILO";
          return `${text} dentro de las 24Hs HÁBILES `;
          // } else {
          //   let text = this.$route.name == "cart" ? "Llega" : "RECIBILO";
          //   return `${text} en 4-6 días hábiles`;
          // }
        } else if (
          getWarehouseReg.current_stock === 0 &&
          getWarehouseFwl01.current_stock > 0
        ) {
          let text = this.$route.name == "cart" ? "Llega" : "RECIBILO";
          return `${text} en 4-6 días hábiles`;
        } else if (
          getWarehouseReg.current_stock > 0 &&
          getWarehouseFwl01.current_stock == 0
        ) {
          let text = this.$route.name == "cart" ? "Llega" : "RECIBILO";
          return `${text} dentro de las 24Hs HÁBILES `;
        } else if (
          getWarehouseReg.current_stock == 0 &&
          getWarehouseFwl01.current_stock == 0
        ) {
          return "AVISAME";
        }
      } else if (
        getWarehouseReg == undefined &&
        getWarehouseFwl01 != undefined
      ) {
        if (getWarehouseFwl01.current_stock > 0) {
          let text = this.$route.name == "cart" ? "Llega" : "RECIBILO";
          return `${text} dentro de las 72Hs HÁBILES`;
        } else if (getWarehouseFwl01.current_stock == 0) {
          return "AVISAME";
        }
      } else if (
        getWarehouseReg != undefined &&
        getWarehouseFwl01 == undefined
      ) {
        if (getWarehouseReg.current_stock > 0) {
          return "Entrega o Retira en el día";
        } else if (getWarehouseReg.current_stock == 0) {
          return "AVISAME";
        }
      }
    },

    ModalProductUser(zip_code, warehouse) {
      if (
        this.HandlerReturnWarehouse(zip_code, warehouse) == "AVISAME" &&
        this.dataProduct.user_product_notification == null
      ) {
        this.showModalReserve = true;
      }
    },

    async HandlerNotification() {
      try {
        this.loading = true;
        const request = {
          store_id: 7,
          product_id: this.dataProduct.product_id,
          publication_id: this.dataProduct.id,
          email: this.authUserData.buyer.email,
          name: this.authUserData.buyer.first_name,
          phone: this.authUserData.buyer.phone,
        };

        await this.$store.dispatch(
          "products/PRODUCT_NOTIFICATION_USER",
          request
        );

        this.showModalReserve = false;
        this.dataProduct.user_product_notification = true;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    validateUmbral() {
      const userZipCode = this.authUserData.zipcode;
      let threshold = 0;
      const dataProductValue = { ...this.dataProduct };

      if (
        dataProductValue.product != null &&
        dataProductValue.product.product_warehouse != null
      ) {
        const productWarehouse = dataProductValue.product.product_warehouse;
        const warehouse = productWarehouse.filter(
          (whr) => whr.warehouse_id == 13 && whr.current_stock > 0
        );

        if (warehouse.length === 0) return false;

        const warehouseThreshold = warehouse.some(
          (whr) => whr.current_stock > this.dataProduct.threshold
        );

        if (warehouseThreshold) {
          threshold = warehouse[0].current_stock - this.dataProduct.threshold;
        }
      }
      return threshold > 0 ? true : false;
    },

    HandlerShowProduct(publication) {
      const encryptedID = this.CryptoJS.AES.encrypt(
        publication.product.id.toString(),
        "MyS3c3rtIdPr0Duct"
      ).toString();
      this.$router.push({
        name: "product_details",
        query: { data: encryptedID },
      });
    },

    HandlerModalAvisame() {
      if (this.isAuth) {
        this.showModalReserve = true;
      } else {
        this.$router.push({ name: "login" });
      }
    },

    getMonth() {
      const currentDay = moment().format("DD");
      const currentMonth = moment().format("MM") - 1;
      if (currentDay < 15) {
        return moment().locale("es").format("MMMM");
      }
      return moment()
        .month(parseInt(currentMonth) + 1)
        .locale("es")
        .format("MMMM");
    },

    centerElement(mb = "mb-0") {
      if (this.$route.name === "home") return `d-flex justify-start ${mb}`;
      return `d-flex justify-center ${mb}`;
    },
  },
};
</script>

<style>
</style>
