var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',[_c('v-img',{attrs:{"src":require("../../assets/img/sections/showRoom.jpg"),"lazy-src":require("../../assets/img/sections/showRoom.jpg")}})],1),_c('v-container',[_c('v-row',{staticClass:"mt-7",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',[_vm._v(" Nuestro Showroom se encuentra en nuestras bodegas central, frente al Estadio Nacional, con facil acceso y cómodo estacionamiento. ")]),_c('div',{staticClass:"chip my-5"},[_c('span',{staticClass:"pl-2"},[_vm._v("Av. Marathon 1315, Ñuñoa, Región Metropolitana, Chile")])]),_c('iframe',{staticStyle:{"border":"0"},attrs:{"src":"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3328.412696333112!2d-70.61970868480036!3d-33.46460458076984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cff4f435a467%3A0x5681715bdf17f635!2sAv.%20Marathon%201315%2C%20%C3%91u%C3%B1oa%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1ses!2sve!4v1643923022758!5m2!1ses!2sve","width":"100%","height":"600","allowfullscreen":"","loading":"lazy"}})]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',{staticClass:"color-text"},[_vm._v(" Reserva una visita a nuestro Showroom ")]),_c('div',{staticClass:"mt-3"},[_c('label',{attrs:{"for":"Nombre"}},[_vm._v("Nombre")]),_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2 elevation-0",attrs:{"color":"#00A0E9","dense":"","filled":"","flat":"","error-messages":errors}})]}}])}),_c('label',{attrs:{"for":"Nombre"}},[_vm._v("Apellido")]),_c('ValidationProvider',{attrs:{"name":"Apellido","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2 elevation-0",attrs:{"color":"#00A0E9","dense":"","filled":"","flat":"","error-messages":errors}})]}}])}),_c('label',{attrs:{"for":"Nombre"}},[_vm._v("Email")]),_c('ValidationProvider',{attrs:{"name":"Emil","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2 elevation-0",attrs:{"color":"#00A0E9","dense":"","filled":"","flat":"","error-messages":errors}})]}}])}),_c('label',{attrs:{"for":"Nombre"}},[_vm._v("Teléfono")]),_c('ValidationProvider',{attrs:{"name":"Emil","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2 elevation-0",attrs:{"color":"#00A0E9","dense":"","filled":"","flat":"","error-messages":errors}})]}}])})],1),_c('div',{staticClass:"simple-example"},[_c('vue-meeting-selector',{attrs:{"date":_vm.date,"loading":_vm.loading,"meetings-days":_vm.meetingsDays},on:{"next-date":_vm.nextDate,"previous-date":_vm.previousDate},model:{value:(_vm.meeting),callback:function ($$v) {_vm.meeting=$$v},expression:"meeting"}})],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }