<template>
  <v-container fluid>
    <div class="custom-nav">
      <div class="d-flex px-0 px-sm-15 px-md-15 nav-position">
        <v-app-bar-nav-icon
          @click="drawer = !drawer"
          v-if="isMobile"
        ></v-app-bar-nav-icon>

        <v-avatar
          size="120"
          tile
          @click="$router.push({ name: 'home' }).catch((err) => err)"
        >
          <v-img
            class="cursor-pointer"
            contain
            @mouseover="megaMenu = false"
            src="@/assets/img/edifier-logo-color.svg"
          ></v-img>
        </v-avatar>
        <div
          v-if="!$vuetify.breakpoint.smAndDown"
          class="px-5"
          @mouseover="megaMenu = false"
          style="color: white; cursor: default"
        >
          .
        </div>
        <div v-if="!isMobile">
          <v-hover v-slot="{ hover }">
            <span
              @mouseover="megaMenu = true"
              :style="
                hover ? 'cursor: pointer; color: #00A0E9' : 'cursor: pointer;'
              "
            >
              Nuestros modelos
            </span>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <span
              class="px-10"
              @mouseover="megaMenu = false"
              @click="goToShowroom"
              :style="
                hover ? 'cursor: pointer; color: #00A0E9' : 'cursor: pointer;'
              "
            >
              Showroom
            </span>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <span
              @mouseover="megaMenu = false"
              @click="goToContact"
              class="text-capitalize pr-10"
              :style="
                hover ? 'cursor: pointer; color: #00A0E9' : 'cursor: pointer;'
              "
            >
              Contacto
            </span>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <span
              @click="goTo()"
              @mouseover="megaMenu = false"
              :style="
                hover ? 'cursor: pointer; color: #00A0E9' : 'cursor: pointer;'
              "
            >
              Soporte técnico
            </span>
          </v-hover>
        </div>

        <v-spacer></v-spacer>

        <!-- BUSCADOR -->
        <v-icon color="black" @click="activeSearch" class="mr-2">
          mdi-magnify
        </v-icon>

        <!-- FAVORITOS AUTENTICADO -->
        <div v-if="!isMobile && favoriteProduct.length > 0">
          <v-btn
            @click="$router.push({ name: 'product_favorite' })"
            v-if="isAuth"
            class="mr-1 ml-2"
            color="black"
            icon
          >
            <v-icon
              class="animate__animated animate__pulse animate__infinite"
              color="red lighten-1"
            >
              mdi-heart
            </v-icon>
          </v-btn>
        </div>

        <!-- PERFIL AUTENTICADO -->
        <div v-if="!isMobile && isAuth == true">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="$router.push({ name: 'profile' })"
                v-if="isAuth"
                class="mr-1 ml-2 elevation-0"
                color="#E9E9E9"
                icon
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="black">mdi-account-circle-outline </v-icon>
              </v-btn>
            </template>
            <span>Perfil</span>
          </v-tooltip>
        </div>

        <v-btn
          v-if="isMobile == true && isAuth == false"
          class="text-capitalize"
          text
          @click="$router.push({ name: 'login' })"
        >
          <v-icon class="mr-1">mdi-account</v-icon>
        </v-btn>

        <v-btn
          v-if="isMobile && isAuth == true"
          @click="HandlerLogout()"
          class="ml-0 mr-0"
          color="black"
          text
          icon
        >
          <v-icon>mdi-exit-to-app </v-icon>
        </v-btn>

        <v-menu
          open-on-hover
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="HandlerRouter('cart')"
              icon
              color="#00A0E9"
              dark
              v-bind="attrs"
              v-on="on"
              class="ml-2"
            >
              <v-icon>mdi-cart-outline</v-icon>
            </v-btn>
          </template>

          <v-card
            v-if="
              productCartState.shopping_cart_items != null &&
              productCartState.shopping_cart_items.length > 0
            "
          >
            <div
              class="pt-3 px-5 d-flex"
              v-for="(item, index) in productCartState.shopping_cart_items"
              :key="index"
            >
              <div>
                <v-avatar tile v-if="item.publication.images == null">
                  <img
                    height="200"
                    width="100%"
                    contain
                    src="@/assets/img/no_image.jpg"
                  />
                </v-avatar>
                <v-avatar v-else tile size="100">
                  <v-img
                    contain
                    :src="item.publication.images[0]"
                    :lazy-src="item.publication.images[0]"
                    alt="Product Image"
                  ></v-img>
                </v-avatar>
              </div>
              <div class="pl-3 align-self-center">
                <div class="">{{ item.publication.keywords }}</div>
                <div
                  class="mt-2"
                  v-if="
                    item.publication != null && item.publication.price != null
                  "
                >
                  $ {{ item.publication.price.pvp | currency }}
                </div>
              </div>
            </div>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                dark
                small
                color="#00a0e9"
                rounded
                @click="HandlerRouter('cart')"
              >
                Lista de compra
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="isMobile == false && isAuth == true"
              class="ml-3"
              v-bind="attrs"
              v-on="on"
              @click="HandlerLogout"
              color="black"
            >
              mdi-exit-to-app
            </v-icon>
          </template>
          <span>Salir</span>
        </v-tooltip>

        <v-btn
          v-if="isMobile == false && isAuth == false"
          class="text-capitalize"
          text
          @click="HandlerRouter('login')"
        >
          <v-icon class="mr-1">mdi-account-outline</v-icon>
          Ingresar
        </v-btn>
      </div>

      <!-- MEGA MENU -->
      <div
        v-if="megaMenu"
        @mouseleave="megaMenu = false"
        class="mega_menu animate__animated animate__fadeIn animate__faster"
        style="background-color: #f8f8f8"
      >
        <v-container>
          <v-row class="mb-10">
            <v-col
              cols="12"
              md="3"
              v-for="(category, index) in categories"
              :key="index"
            >
              <v-hover v-slot="{ hover }">
                <div
                  @click="HandlerGetPublicProducts(category, 1)"
                  :style="
                    hover
                      ? 'font-size: 1.3em; cursor: pointer; color: #00A0E9'
                      : 'font-size: 1.3em; cursor: pointer'
                  "
                  class="mt-7 mb-5 text-capitalize"
                >
                  {{ category.name }}
                </div>
              </v-hover>
              <div
                v-if="
                  category.name != 'Línea S' &&
                  category.name != 'Gaming' &&
                  category.name != 'Todo' &&
                  category.name != 'Portátiles'
                "
              >
                <div
                  v-for="(sub_cat, j) in category.sub_category"
                  :key="j"
                  class="text-capitalize mb-3"
                >
                  <span
                    @click="HandlerGetPublicProducts(sub_cat, 2)"
                    style="cursor: pointer"
                  >
                    {{ sub_cat.name }}
                  </span>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <v-navigation-drawer
      v-model="drawer"
      bottom
      color="transparent"
      fixed
      height="auto"
      overlay-color="secondary"
      overlay-opacity=".6"
      temporary
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-list color="white" shaped>
        <v-list-item-group color="primary">
          <v-list-item v-for="(item, i) in drawerItems" :key="i">
            <v-list-item-content>
              <v-btn
                v-if="item.func == true"
                color="#2696A6"
                text
                class="font-medium text-capitalize"
                @click="HandlerRoute(item.href, item.value)"
              >
                {{ item.text }}
              </v-btn>
              <v-btn
                v-if="item.func == false"
                color="#2696A6"
                text
                @click="HandlerRoute(item.href, item.value)"
                class="font-medium text-capitalize"
              >
                {{ item.text }}
              </v-btn>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      drawer: false,
      megaMenu: false,
      group: null,
      showModalContact: false,

      // Loading
      loadingProducts: false,

      // search
      search: false,

      // CART
      fav: true,
      menu: false,
      message: false,
      hints: true,

      //Categories
      categories: [],
    };
  },

  created() {
    this.GetCategories();
  },

  watch: {
    isMobile(val) {
      if (!val) this.drawer = false;
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    isAuth() {
      return this.$store.getters["auth/AUTHENTICATED"];
    },

    favoriteProduct() {
      return this.$store.getters["products/GET_PRODUCT_FAVORITES"];
    },

    drawerItems() {
      const isAuth = this.$store.getters["auth/AUTHENTICATED"];
      let items;
      if (isAuth) {
        items = [
          { text: "Inicio", func: true, href: "home", value: "home" },
          { text: "Perfil", func: false, href: "profile", value: "profile" },
          {
            text: "Productos",
            func: false,
            href: "products",
            vlue: "products",
          },
          {
            text: "Carrito",
            func: false,
            href: "cart",
            value: "cart",
          },
          {
            text: "Favoritos",
            func: false,
            href: "product_favorite",
            value: "product_favorite",
          },
        ];
      } else {
        items = [
          { text: "Inicio", func: false, href: "home", value: "home" },
          {
            text: "Productos",
            func: false,
            href: "products",
            value: "products",
          },
        ];
      }
      return items;
    },

    productCartState() {
      return this.$store.getters["cart/CART_PRODUCTS"];
    },

    products() {
      const cart = this.$store.getters["cart/CART_PRODUCTS"];
      return cart.length == 0 ? [] : cart.shopping_cart_items;
    },
  },

  methods: {
    HandlerRouter(router) {
      if (router == "cart") {
        if (this.isAuth) {
          this.$router.push({ name: router }).catch((err) => {});
        } else {
          this.$router.push({ name: "login" }).catch((err) => {});
        }
      } else {
        this.$router.push({ name: router }).catch((err) => {});
      }
    },

    HandlerRoute(route, value) {
      this.$router.push({ name: route }).catch((err) => {});
    },

    async HandlerLogout() {
      try {
        this.$store.commit("auth/CLEAR_DATA_LOGOUT");
        this.$store.commit("cart/CLEAN_CART");
        this.$router.push({ name: "home" }).catch((err) => err);
      } catch (error) {
        console.log(error);
      }
    },

    async GetCategories() {
      try {
        const request = {
          store: 7,
          page: 1,
          per_page: 10,
          paginate: true,
          everything: false,
        };
        const response = await this.$store.dispatch(
          "products/GET_CATEGORIES",
          request
        );
        this.categories = response.data.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetPublicProducts(item, value) {
      if (value == 1) {
        const category_id = JSON.parse(item.id);
        this.$router
          .push({ name: "products", query: { data: category_id } })
          .catch((err) => err);
      } else {
        const sub_category_id = JSON.parse(item.id);
        this.$router
          .push({
            name: "products",
            query: { sub_data: sub_category_id },
          })
          .catch((err) => err);
      }
      this.megaMenu = false;
    },

    activeSearch() {
      this.$store.commit("activeSearch");
    },

    goTo() {
      window.open("http://www.edifierla.com/hola/");
    },

    goToContact() {
      const url = process.env.VUE_APP_CHECKOUT;
      location.href = `${url}/contact`;
    },

    goToShowroom() {
      // window.open("https://edifiercl.reamaze.com/chat-with-us/25303");
      this.$router.push({name: "showRoom"});
    }
  },
};
</script>

<style>
</style>
